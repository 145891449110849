import { Box, Button, Grid, Icon, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import FilterButtons from "components/FilterButtons";
import { MY_CUSTOMERS_OPTIONS } from "components/FilterButtons/options";
import DataList from "components/DataList";
import MDTypography from "components/MDTypography";
import Searchbar from "components/Searchbar";
import { baseAxios } from "config";
import useSort from "utils/useSort";
import { useAppContextController } from "context/AppContext";
// import useCompanyCache from "hooks/useCompanyCache";
// import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import fetchCustomers from "api/companies/fetchCustomers";
import MDBox from "components/MDBox";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import { filterUserCustomers } from "utils/helpers/customers";
import useCompanyStore from "stores/useCompanyStore";
import useSessionAuth from "hooks/useSessionAuth";
import MyCustomersActions from "../MyCustomersActions";

const MyCustomers = ({ fetchAll = false, setFilters, filters }) => {
  const {
    setCurrentCustomer,
    currentLoggedUser,
    setCurrentLoggedUser,
    userType,
    // setCustomers,
    company,
    // setCompany,
    // setCompanyType,
  } = useAppContextController();

  // const { isLoadingCompany, refetchCompany } = useCompanyCache({
  //   company,
  //   setCompany,
  //   setCompanyType,
  // });

  const { currentCompany, setCurrentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
    setCurrentCompany: state.setCurrentCompany,
  }));

  const [myCustomers, setMyCustomers] = useState([]);
  const [userIsLoading, setUserIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  // const [isCustomerPreviewOpen, toggleCustomerPreview] = useState(false);
  // const [selectedCustomer, setSelectedCustomer] = useState({});
  const [userApplicant, setUserApplicant] = useState(null);
  const [dataFilter, setDataFilter] = useState({
    status: "all",
  });

  const { logoutAuth0User } = useSessionAuth();

  const myCustomersMutation = useMutation(async ({ data, id }) => {
    // eslint-disable-next-line
    if (data._id) delete data._id;

    try {
      await baseAxios.put(`/users/${id}`, data);
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }

    setCurrentLoggedUser({ _id: id, ...currentLoggedUser, ...data });
  });

  const { order, orderBy, toggleSort } = useSort();
  const options = fetchAll ? { fetchAll } : { page, limit: null, order, orderBy };
  const allOptions = {
    ...options,
    filters: { ...filters, status: "Active;Prospect", primaryCompany: false },
  };
  // const [toastAlert, setToastAlert] = useState(false);
  const { data: companies, isLoading } = useQuery(
    ["mycustomers", allOptions],
    () => fetchCustomers(allOptions),
    { refetchInterval: 300000 }
  );

  const setAndRequery = (customerSlug) => {
    const newCustomers = myCustomers
      .filter((item) => item?.slug !== customerSlug)
      .map((customer) => customer?.slug);
    myCustomersMutation.mutate({
      data: { companies: [...newCustomers] },
      id: currentLoggedUser.userId,
    });
  };

  // const sortCustomers = (a, b) => {
  //   if (a.name > b.name) return 1;
  //   return -1;
  // };

  // useEffect(() => {
  //   if (!companies || !Object.keys(companies)?.length) {
  //     refetchCompany();
  //   }
  // }, []);

  useEffect(() => {
    const setUserCustomers = async (appId) => {
      const app = await fetchSingleApplicant({ applicantId: appId });
      setUserApplicant(app.data);

      const costumerList = app?.data?.companies
        ?.filter((item) => item.companySlug)
        ?.map((item) => item.companySlug);
      if (costumerList?.length && companies?.data) {
        const tempList =
          companies?.data?.filter((item) => {
            return currentLoggedUser.companies.includes(item.slug);
          }) || [];
        if (userType === "User") setMyCustomers(tempList);
      }
    };

    if (currentLoggedUser) {
      if (userType === "User") {
        setUserCustomers(currentLoggedUser.applicantId);
        setUserIsLoading(false);
      } else if (userType && userType !== "User" && companies && Object.keys(companies)?.length) {
        if (currentLoggedUser.companies && typeof currentLoggedUser.companies !== "string") {
          setMyCustomers(
            companies?.data?.sort().filter((item) => {
              return currentLoggedUser.companies.includes(item.slug);
            }) || []
          );
        } else {
          setMyCustomers([]);
        }
        setUserIsLoading(false);
      }
    }
  }, [currentLoggedUser, companies, userType]);

  const navigate = useNavigate();
  const addNew = () => {
    setCurrentCompany({});
    navigate("/customers/create");
  };
  const viewAll = () => navigate(`/customers${userType !== "User" ? "?myCustomers=true" : ""}`);

  const columns = [
    {
      title: "Logo",
      field: "logoUrl",
      type: "image",
      imageWidth: "50",
      imageHeight: "50",
      width: "10%",
    },
    {
      title: "Customer ID",
      field: "slug",
      customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      width: "25%",
    },

    { title: "Customer Name", field: "name", width: "30%" },
    { title: "City", field: "city", width: "15%" },
    { title: "State", field: "state", width: "15%" },
  ];

  const userColumns = [
    {
      title: "Logo",
      field: "logoUrl",
      imageWidth: "50",
      imageHeight: "50",
      customCell: (logoUrl, row) =>
        row ? <CompanyLogoSquare company={row} defaultCompany={company} /> : null,
      width: "15%",
    },
    {
      title: "Customer Name",
      field: "name",
      width: "50%",
      customCell: (name) => `${name.length > 20 ? name.substring(0, 20) : name}...`,
    },
    {
      title: "City/State",
      field: "city",
      customCell: (city, row) => `${city}, ${row?.state}`,
      width: "25%",
    },
  ];

  const renderRow = (row) => {
    return (
      <>
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item xs={12} md={6} sx={{ display: "flex", gap: "10px" }}>
            <CompanyLogoSquare company={row} defaultCompany={company} />
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <MDTypography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {row?.name}
                </MDTypography>
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="body2">
                  {row?.city}
                  {row?.city && row?.state ? ", " : ""}
                  {row?.state} {row?.city || row?.state ? `-` : " "}
                  <MDTypography
                    variant="body2"
                    mt={0}
                    pt={0}
                    color={row?.status === "Prospect" ? "warning" : "success"}
                    style={{ display: "inline", fontWeight: "bold" }} // Add this line
                  >
                    {" "}
                    {row?.status}
                  </MDTypography>
                </MDTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <MyCustomersActions
              isFavorite={() => true}
              setFavorite={setAndRequery}
              slug={row?.slug}
              // openCustomerPreview={() => {
              //     setSelectedCustomer(row);
              //     toggleCustomerPreview(true);
              // }}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  // const searchBarRowClick = (item) => {
  //   setCurrentCustomer(item);
  //   navigate(`/customers/${item?.slug}/action/customerinfo`);
  // };

  const columnsForSearch = [
    {
      title: "Logo",
      field: "name",
      width: 40,
      customCell: (name, field, row) => (
        <CompanyLogoSquare company={row} defaultCompany={company} />
      ),
    },
    { title: "Customer Name", field: "name", width: 200 },
    // { title: "Last Name", field: "lastName" },
    // { title: "Email/Name ID", field: "emailAddress" },
    {
      title: "City/State",
      field: "city",
      width: 100,
      customCell: (city, row, field) =>
        city ? (
          <span>
            {city} {row?.state}
          </span>
        ) : (
          ""
        ),
    },
  ];

  const header = (
    <>
      <Stack display="flex" flexDirection="row" pb={2} pr={3}>
        <Grid container>
          <Grid item sm={6} display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="info"
              color="white"
              borderRadius="xl"
              mt={-2}
            >
              <Icon fontSize="large">business</Icon>
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1, ml: 2 }}>
              My Customers
            </MDTypography>
          </Grid>
          <Grid item sm={6} alignSelf="center">
            <Box>
              <Button variant="text" color="primary" onClick={viewAll}>
                View All
              </Button>
              {userType !== "User" && (
                <>
                  |
                  <Button variant="text" color="primary" onClick={addNew}>
                    Add New
                  </Button>
                </>
              )}
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Searchbar
              fetch={fetchCustomers}
              fetchAll
              placeholder="Search Customers"
              columns={columnsForSearch}
              queryCharacterLimit={2}
              resultsLimit={10}
              setFilters={setFilters}
              setPage={setPage}
              searchBy={["name", "city", "slug"]}
              filterBy="slug"
              onRowClick={(comp, e) => {
                setCurrentCompany(comp);
                navigate(`/customers/${comp?.slug}/action/customerinfo`);
                e.stopPropagation();
              }}
            />
          </Grid>
          <Grid item sm={6}>
            <FilterButtons
              options={MY_CUSTOMERS_OPTIONS}
              filter={dataFilter}
              setFilter={setDataFilter}
              name="status"
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  );

  const sortCompanies = (a, b) => {
    if (a.name > b.name) return 1;
    return -1;
  };

  const filteredData = useMemo(() => {
    if (dataFilter.status === "all") {
      return myCustomers;
    }
    return filterUserCustomers(dataFilter.status, myCustomers);
  }, [dataFilter, myCustomers]);

  return (
    <>
      <DataList
        renderRow={renderRow}
        data={filteredData?.sort(sortCompanies)}
        // icon="stadium"
        // iconColor="info"
        // title="My Customers"
        userIsLoading={userIsLoading}
        greybar
        divider
        noIcon
        header={header}
        tableHeight={367}
        height={500}
        scrollY
      />
    </>
  );
};

export default MyCustomers;
